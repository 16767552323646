/***************************************************
  Imports
***************************************************/

import React from "react"
import { Link } from "gatsby"
import { useRecoilState } from "recoil"

import { menuState } from "../state"
import CloseGraphic from "../images/menu-close.svg"

/***************************************************
  Component
***************************************************/

export default () => {
  const [isMenuOpen, toggleMenu] = useRecoilState(menuState)

  return (
    <nav className={`sidebar-menu ${isMenuOpen && "open"}`}>
      <div className="close">
        <div onClick={() => toggleMenu()}>
          <img src={CloseGraphic} alt="Close Menu" />
        </div>
      </div>
      <div className="items">
        <Link to="/" onClick={() => toggleMenu()}>
          HOME
        </Link>
        <Link to="/#products" onClick={() => toggleMenu()}>
          PRODUCTS
        </Link>
        <Link
          to="/microsoft-dynamics-365-business-central"
          onClick={() => toggleMenu()}
        >
          MICROSOFT DYNAMICS 365 BUSINESS CENTRAL
        </Link>
        <Link to="/#industries" onClick={() => toggleMenu()}>
          INDUSTRIES
        </Link>
        <Link to="/#simcrest-apps" onClick={() => toggleMenu()}>
          {" "}
          SIMCREST APPS
        </Link>
        <Link to="/#services" onClick={() => toggleMenu()}>
          SERVICES
        </Link>
        <Link to="/methodologies" onClick={() => toggleMenu()}>
          METHODOLOGIES
        </Link>
        <Link to="/about-us" onClick={() => toggleMenu()}>
          ABOUT US
        </Link>
        <Link to="/contactus" onClick={() => toggleMenu()}>
          CONTACT US
        </Link>
      </div>
    </nav>
  )
}
