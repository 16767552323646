/***************************************************
  Imports
***************************************************/

import React, { useEffect } from "react"
import { useSetRecoilState } from "recoil"

import globalStyles from "../styles/global.scss"

import { headerState, linkedSubPagesState } from "../state"

import SEO from "./SEO"
import Developer from "./Developer"
import Header from "./Header"
import SidebarMenu from "./SidebarMenu"
import Footer from "./Footer"
import { useRecoilState } from "recoil"

/***************************************************
  Component
***************************************************/

export default ({ children, headerTheme, className, title, description }) => {
  const [curHeadertheme, setHeaderTheme] = useRecoilState(headerState)
  if (headerTheme && headerTheme !== curHeadertheme) setHeaderTheme(headerTheme)

  const setD365Group = useSetRecoilState(linkedSubPagesState("D365Group"))
  const setIndustriesGroup = useSetRecoilState(
    linkedSubPagesState("IndustriesGroup")
  )
  const setSimCrestAppsGroup = useSetRecoilState(
    linkedSubPagesState("SimCrestAppsGroup")
  )
  useEffect(() => {
    setD365Group(LINKED_SUBPAGES.D365Group)
    setIndustriesGroup(LINKED_SUBPAGES.IndustriesGroup)
    setSimCrestAppsGroup(LINKED_SUBPAGES.SimCrestAppsGroup)
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Developer />
      <Header />
      <SidebarMenu />
      <div className={className}>{children}</div>
      <Footer />
    </>
  )
}

/***************************************************
  Page Groups
***************************************************/

const LINKED_SUBPAGES = {
  D365Group: {
    title: "Microsoft Dynamics 365 Business Central",
    options: [
      {
        title: "Financial Management",
        to: "/microsoft-dynamics-365-business-central/financial-management",
      },
      {
        title: "Supply Chain Management",
        to: "/microsoft-dynamics-365-business-central/supply-chain-management",
      },
      {
        title: "Manufacturing",
        to: "/microsoft-dynamics-365-business-central/manufacturing",
      },
      {
        title: "Sales and Marketing",
        to: "/microsoft-dynamics-365-business-central/sales-and-marketing",
      },
      {
        title: "Business Intelligence",
        to: "/microsoft-dynamics-365-business-central/business-intelligence",
      },
      {
        title: "Human Resource Management",
        to:
          "/microsoft-dynamics-365-business-central/human-resource-management",
      },
      {
        title: "Service Management",
        to: "/microsoft-dynamics-365-business-central/service-management",
      },
      {
        title: "Advanced Supply Chain Management",
        to:
          "/microsoft-dynamics-365-business-central/advanced-supply-chain-management",
      },
      {
        title: "RoleTailored",
        to: "/microsoft-dynamics-365-business-central/roletailored",
      },
      {
        title: "Featured Benefits",
        to: "/microsoft-dynamics-365-business-central/featured-benefits",
      },
      {
        title: "Project Management",
        to: "/microsoft-dynamics-365-business-central/project-management",
      },
    ],
  },
  IndustriesGroup: {
    title: "Industries",
    options: [
      {
        title: "Distribution",
        to: "/industries/distribution",
      },
      {
        title: "Food Banks",
        to: "/industries/food-banks",
      },
      {
        title: "Manufacturing",
        to: "/industries/manufacturing",
      },
      {
        title: "Other Industries",
        to: "/industries/other-industries",
      },
      {
        title: "Professional Service",
        to: "/industries/professional-service",
      },
    ],
  },
  SimCrestAppsGroup: {
    title: "SimCrest Apps",
    options: [
      {
        title: "Auto Email",
        to: "/simcrest-apps/auto-email",
      },
      {
        title: "Cash Basis",
        to: "/simcrest-apps/cash-basis",
      },
      {
        title: "Commissions",
        to: "/simcrest-apps/commissions",
      },
      {
        title: "E-Bank Reconciliation",
        to: "/simcrest-apps/ebank-reconciliation",
      },
      {
        title: "Product Configurator",
        to: "/simcrest-apps/product-configurator",
      },
      {
        title: "Time and Billing",
        to: "/simcrest-apps/time-and-billing",
      },
    ],
  },
}
