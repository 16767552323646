/***************************************************
  Imports
***************************************************/

import React, { useState, useEffect } from "react"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { menuState, headerState } from "../state"

import { Link } from "gatsby"

import logoImg from "../images/logo.svg"
import logoBlackImg from "../images/logo-black.svg"

import menuImg from "../images/menu.svg"
import menuBlackImg from "../images/menu-black.svg"

/***************************************************
  Constants/Variables
***************************************************/

const images = {
  light: {
    logo: logoImg,
    menuBar: menuImg,
    menuSpanClass: "color-white",
  },
  dark: {
    logo: logoBlackImg,
    menuBar: menuBlackImg,
    menuSpanClass: "color-black",
  },
}

/***************************************************
  Component
***************************************************/

export default () => {
  const [scrolled, setScrolled] = useState(false)
  const toggleMenu = useSetRecoilState(menuState)
  const headerTheme = useRecoilValue(headerState)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  return (
    <div className="header">
      <header
        className={`section-header ${scrolled && "scrolled"} ${headerTheme}`}
      >
        <div className="col-3 span-10">
          <Link to="/" className="logo">
            <img
              src={!scrolled ? images[headerTheme].logo : images.dark.logo}
              alt="Logo"
            />
          </Link>
          <div className="menu">
            <div onClick={() => toggleMenu()}>
              <span
                className={
                  !scrolled
                    ? images[headerTheme].menuSpanClass
                    : images.dark.menuSpanClass
                }
              >
                MENU
              </span>
              <img
                src={
                  !scrolled ? images[headerTheme].menuBar : images.dark.menuBar
                }
                alt="Menu"
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
