/***************************************************
  Imports
***************************************************/

import React from "react"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <div className="developer">
      <div className="center-line">
        <div></div>
      </div>
      <section className="section-developer">
        <div className="col-1 span-1"></div>
        <div className="col-2 span-1"></div>
        <div className="col-3 span-1"></div>
        <div className="col-4 span-1"></div>
        <div className="col-5 span-1"></div>
        <div className="col-6 span-1"></div>
        <div className="col-7 span-1"></div>
        <div className="col-8 span-1"></div>
        <div className="col-9 span-1"></div>
        <div className="col-10 span-1"></div>
        <div className="col-11 span-1"></div>
        <div className="col-12 span-1"></div>
        <div className="col-13 span-1"></div>
        <div className="col-14 span-1"></div>
      </section>
    </div>
  )
}
