import { atom, atomFamily, selector } from "recoil"

const menuStateAtom = atom({
  key: "menuStateAtom",
  default: false,
})

export const menuState = selector({
  key: "menuState",
  get: ({ get }) => get(menuStateAtom),
  set: ({ set, get }) => set(menuStateAtom, !get(menuStateAtom)),
})

export const headerState = atom({
  key: "headerState",
  default: "light",
})

export const serviceState = atomFamily({
  key: "serviceState",
  default: param => param,
})

export const linkedSubPagesState = atomFamily({
  key: "linkedSubPagesState",
})

export const firstTimeAction = atomFamily({
  key: "firstTimeAction",
  default: true,
})
